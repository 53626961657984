import $ from 'jquery';

let timeout = null;

const ready = () => {
    clearTimeout(timeout);
    $('body').addClass('ready').trigger('customReady');
    $('#preload').addClass('hide');
};

$(() => {
    $(window).on('load', ready);

    timeout = setTimeout(() => {
        ready();
    }, 3000);
});