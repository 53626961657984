import $ from 'jquery';
import Isotope from 'isotope-layout';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/src/js/ui/photoswipe-ui-default';
import 'photoswipe/src/css/main.scss';
import 'photoswipe/src/css/default-skin/default-skin.scss';

const items = [];

const sizes = {
    s: 600,
    m: 1200,
    l: 2048
};

const minSizes = {
    m: 800,
    l: 1500
};

let currentSize = 's';

const calcCurrentSize = () => {
    const width = $(window).width() * window.devicePixelRatio;
    currentSize = 's';

    for (let key in minSizes) {
        if (minSizes.hasOwnProperty(key) && minSizes[key] > width) {
            return;
        }

        currentSize = key;
    }
};

$(window).on('resize', calcCurrentSize);
calcCurrentSize();

let loadingItems = [];
let timeout = null;
const delay = 100;

const showNext = () => {
    if (!loadingItems.length) {
        return;
    }

    const item = loadingItems.shift();
    const $item = $(item);

    if ($item.hasClass('loaded')) {
        timeout = setTimeout(showNext, delay);
        return;
    }

    const url = '/photo/' + $item.data('id') + '_' + (currentSize === 's' ? 's' : 'm') + '.jpg';

    const show = () => {
        $item.find('.img').css('backgroundImage', 'url("' + url + '")');
        $item.addClass('loaded');
        observer.unobserve(item);

        timeout = setTimeout(showNext, delay);
    };

    if (window.Image) {
        const image = new Image();
        image.src = url;
        image.onload = show;
    } else {
        show();
    }
};

const observer = new IntersectionObserver(entries => {
    clearTimeout(timeout);

    entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
            loadingItems.push(entry.target);
        } else {
            const index = loadingItems.indexOf(entry.target);
            if (index > -1) {
                loadingItems.splice(index, 1);
            }
        }
    });

    timeout = setTimeout(showNext, delay);
});

const loadGallery = (startIndex) => {
    const options = {
        index: startIndex,
        closeOnScroll: false,
        galleryPIDs: true
    };

    const template = document.querySelectorAll('.pswp')[0];
    const gallery = new PhotoSwipe(template, PhotoSwipeUI_Default, items, options);

    gallery.listen('gettingData', function (index, item) {
        item.src = '/photo/' + item.pid + '_' + currentSize + '.jpg';

        if (item.o === 'p') {
            item.w = sizes[currentSize];
            item.h = item.w / item.ratio;
        } else {
            item.w = sizes[currentSize];
            item.h = item.w / item.ratio;
        }
    });

    gallery.init();
};

const getHashParams = function () {
    const hash = window.location.hash.substring(1);

    let params = {};

    if (hash.length < 5) {
        return params;
    }

    const vars = hash.split('&');
    for (let i = 0; i < vars.length; i++) {
        if (!vars[i]) {
            continue;
        }
        const pair = vars[i].split('=');
        if (pair.length < 2) {
            continue;
        }
        params[pair[0]] = pair[1];
    }

    if (params.gid) {
        params.gid = parseInt(params.gid, 10);
    }

    return params;
};


$(function () {
    const $body = $('body');
    const $container = $('#gallery-container');
    const $items = $container.find('.item');

    $items.each((i, el) => {
        observer.observe(el);
        const $item = $(el);
        let title = null;

        switch ($item.data('creator')) {
            case 'fio':
                title = 'by Fiorenzo Maletta / Sarah Maletta';
                break;
            case 'elia':
                title = 'by Elia Maletta / Sarah Maletta';
                break;
            case 'beka':
                title = 'Beka Bitterli fotografiert Emotionen';
                break;
        }

        items.push({
            pid: $item.data('id'),
            o: $item.hasClass('p') ? 'p' : 'h',
            ratio: $item.data('ratio'),
            title
        });

        $item.on('click', () => {
            if (!$item.hasClass('loaded')) {
                clearTimeout(timeout);
                loadingItems.push($item[0]);
                setTimeout(showNext, delay);
            }

            loadGallery(i)
        });
    });

    $body.one('customReady', () => {
        const params = getHashParams();

        if (params.pid) {
            const $item = $('[data-id=' + params.pid + ']');

            if ($item.length) {
                loadGallery($items.index($item));
            }
        }
    });

    const isotope = new Isotope($container[0], {
        itemSelector: '.item',
    });
    isotope.layout();
    $body.one('customReady', () => isotope.layout());
});