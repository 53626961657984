// SCSS
import "./scss/bootstap.scss";
import "./scss/header.scss";
import "./scss/preload.scss";
import "./scss/gallery.scss";

//JS
import "intersection-observer";
import "./js/preload";
import "./js/fontawesome";
import "./js/gallery";
import "./js/header";