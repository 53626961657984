import $ from 'jquery';

$(() => {
    const updateHeight = () => $('header').height($(window).height() + 5);
    $(window).on('resize', updateHeight);
    updateHeight();

    const $body = $('body');
    const $more = $('#more');

    window.scrollTo(0, 1);
    $body.scrollTop(1);

    $body.on('customReady', () => {
        $more.addClass('animate');

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio === 0) {
                    $more.removeClass('animate');
                    observer.unobserve(entry.target);
                    observer.disconnect();
                }
            });
        });

        observer.observe($more[0]);
    });

    $more.on('click', () => {
        $body.animate({
            scrollTop: $('#gallery').offset().top
        }, 500);
    });
});